import {sanitizeHtml} from '../utils/markupHelpers.js';

/**
 * @module alert
 * @description Provide methods to display messages.
 * @example Notification.warning('Failed to remove a lesson item from a lesson');
 * // add warning alert to the body element
 *
 * @example Notification.info('Notify a user for info.', document.querySelector('#customContainer'));
 * // add info alert to some custom container
 *
 * @example Notification.success('Notify a user for info.', null, () => {alert('Message from callback');});
 * // add success alert with custom callback
 */

/**
 * Create root container for alert
 * @param {HTMLElement} target
 * @returns {HTMLElement} element that hold all the alerts
 */
const createRootContainer = (target) => {
    if (!target.querySelector(`${target.tagName} > .alertContainer`)) {
        // Alert container is not created

        const gridContent = document.createElement('div');
        gridContent.className = 'gridContent';
        const alertContainer = document.createElement('div');
        alertContainer.className = 'alertContainer';

        // add `page` element when container is created in body
        // this is to make container always at the fixed position
        if (target === document.body) alertContainer.className += ' alertContainer--page gridContainer';
        // Add inline class to have different behaviour for inline alert
        else alertContainer.className += ' alertContainer--inline';

        alertContainer.append(gridContent);
        target.insertBefore(alertContainer, target.firstChild);

        return gridContent;
    }

    // Otherwise, container already exist
    return target === document.body
        ? target.querySelector(`${target.tagName} > .alertContainer > .gridContent`)
        : target.querySelector(`${target.tagName} > .alertContainer`);
};

/**
 * Generate notification element
 * @param {string} type type of notification
 * @param {string} message
 * @param {string|null} [title] optional title field
 * @returns {HTMLElement} notification element
 */
const createNotificationElement = (type, message, title) => {
    const notification = document.createElement('div');
    notification.className = `alertMessage alertMessage--${type}`;
    notification.setAttribute('aria-live', 'polite');
    notification.setAttribute('role', 'status');

    // notification body

    let content =
        `<div class="${title ? 'alertMessage__subtitle' : 'alertMessage__text'}">${sanitizeHtml(message)}</div>` +
        `<button type="button" class="alertMessage__closeButton" aria-label="Hide notification">×</button>`;

    if (title) {
        content = `<div class='alertMessage__title'>${sanitizeHtml(title)}</div>` + content;
    }

    notification.insertAdjacentHTML('afterbegin', content);

    return notification;
};

/**
 * Adds notification to the alert container
 * If there is an existing node then this pushes the existing node down by creating
 * a temp div and increase its height
 * @param {HTMLElement} alertContainer
 * @param {HTMLElement} notification
 */
function addNotification(alertContainer, notification) {
    if (alertContainer.children.length > 0) {
        // Get height of the newly added notification
        // set it invisible
        notification.style.visibility = 'hidden';
        document.body.append(notification);
        const {height} = notification.getBoundingClientRect();
        notification.remove();
        // set it back to visible
        notification.style.visibility = 'visible';

        // Create filler div that will increase in height
        const fillerDiv = document.createElement('div');
        fillerDiv.className = 'alertMessage__fillerDiv';
        alertContainer.prepend(fillerDiv);

        // Listen to transition end event to remove filler div and append notification
        const transitionEnd = ['webkitTransitionEnd', 'oTransitionEnd', 'mstransitionEnd', 'transitionend'];
        const onTransitionOut = () => {
            transitionEnd.forEach((event) => alertContainer.removeEventListener(event, onTransitionOut));
            fillerDiv.remove();
            alertContainer.prepend(notification);
        };
        transitionEnd.forEach((event) => fillerDiv.addEventListener(event, onTransitionOut));

        // Need to increase height after element successfully added and event listener
        // running, setTimeout helps us achieve that
        setTimeout(() => {
            const notificationPadding = 32;
            fillerDiv.style.height = `${height + notificationPadding}px`;
        }, 0);
    } else {
        alertContainer.prepend(notification);
    }
}

/**
 * Show an alert box with the specified type and message.
 * The alert box will be prepended to the specified $container.
 *
 * @private
 * @param {!string} type The alert box type, success|warning|info|alert|secondary.
 * @param {!(string|MessageWithTitle)} message Text to display
 * @param {HTMLElement} [container] element to hold alerts element
 * @param {Function} [onNotificationDisplayedCallback] Callback calls after prepending
 * @param {boolean} autoClose Notification should auto-close after 10 seconds
 * @returns {*} Value returned by optional callback or boolean true
 */
function show(type, message, container = document.body, onNotificationDisplayedCallback = null, autoClose = false) {
    // Generate alert container if not available
    const alertContainer = createRootContainer(container);

    let messageString = message;
    let titleString = null;
    if (Object.prototype.hasOwnProperty.call(message, 'message')) {
        messageString = message.message;
        titleString = message.title;
    }

    // build notification
    const notification = createNotificationElement(type, messageString, titleString);

    addNotification(alertContainer, notification);
    // closing animation end listener
    const animationEndEvents = ['webkitAnimationEnd', 'oAnimationEnd', 'msAnimationEnd', 'animationend'];
    const onNotificationTransitOut = () => {
        // Remove animation end listener
        animationEndEvents.forEach((event) => notification.removeEventListener(event, onNotificationTransitOut));
        // and notification block
        notification.remove();
    };

    const onUserCloseNotification = (clickEvent) => {
        // Immediately remove click event listener
        clickEvent.currentTarget.removeEventListener('click', onUserCloseNotification);

        // listen to animation end
        animationEndEvents.forEach((event) => notification.addEventListener(event, onNotificationTransitOut));

        // alertContainer.removeChild(notification);
        notification.classList.add('alertMessage--closingAnimation');
    };

    /** @type {HTMLButtonElement} */
    const closeButton = notification.querySelector('.alertMessage__closeButton');
    closeButton.addEventListener('click', onUserCloseNotification);

    if (autoClose) {
        setTimeout(() => {
            closeButton.click();
        }, 10000);
    }

    if (typeof onNotificationDisplayedCallback === 'function') {
        return onNotificationDisplayedCallback(alertContainer);
    }

    return true;
}

/**
 * @typedef {object} MessageWithTitle
 * @property {string} message undocumented
 * @property {string} title undocumented
 */

export class Notification {
    /**
     * Show success alert box with the specified type and message.
     * The alert box will be prepended to the specified container.
     *
     * @param {!(string|MessageWithTitle)} message Text to display
     * @param {HTMLElement} [container] If not provided - use document.body
     * @param {Function} [onNotificationDisplayedCallback] Callback calls after prepending
     * @param {boolean} autoClose Notification should auto-close after 10 seconds
     * @returns {*} Value returned by optional callback or boolean true
     */
    static success(message, container, onNotificationDisplayedCallback, autoClose = false) {
        return show('success', message, container, onNotificationDisplayedCallback, autoClose);
    }

    /**
     * Show info alert box with the specified type and message.
     * The alert box will be prepended to the specified container.
     *
     * @param {!(string|MessageWithTitle)} message Text to display
     * @param {HTMLElement} [container] If not provided - use document.body
     * @param {Function} [onNotificationDisplayedCallback] Callback calls after prepending
     * @param {boolean} autoClose Notification should auto-close after 10 seconds
     * @returns {*} Value returned by optional callback or boolean true
     */
    static info(message, container, onNotificationDisplayedCallback, autoClose = false) {
        return show('info', message, container, onNotificationDisplayedCallback, autoClose);
    }

    /**
     * Show warning alert box with the specified type and message.
     * The alert box will be prepended to the specified container.
     *
     * @param {!(string|MessageWithTitle)} message Text to display
     * @param {HTMLElement} [container] If not provided - use document.body
     * @param {Function} [onNotificationDisplayedCallback] Callback calls after prepending
     * @param {boolean} autoClose Notification should auto-close after 10 seconds
     * @returns {*} Value returned by optional callback or boolean true
     */
    static warning(message, container, onNotificationDisplayedCallback, autoClose = false) {
        return show('warning', message, container, onNotificationDisplayedCallback, autoClose);
    }

    /**
     * Show alert box with the specified type and message.
     * The alert box will be prepended to the specified container.
     *
     * @param {!(string|MessageWithTitle)} message Text to display
     * @param {HTMLElement} [container] If not provided - use document.body
     * @param {Function} [onNotificationDisplayedCallback] Callback calls after prepending
     * @param {boolean} autoClose Notification should auto-close after 10 seconds
     * @returns {*} Value returned by optional callback or boolean true
     */
    static error(message, container, onNotificationDisplayedCallback, autoClose = false) {
        return show('error', message, container, onNotificationDisplayedCallback, autoClose);
    }
}
